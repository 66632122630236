import React from "react";
import { Avatar, Box, Text, Heading, HStack, Center, Grid, GridItem, Button, IconButton, VStack, Input, Link, UnorderedList, ListItem } from "@chakra-ui/react";
import { SearchIcon } from '@chakra-ui/icons'


export default function Faq(props) {
  return (

<VStack align="center" spacing="24px">



<Box p={5} width={{ base: "100%", md: "75%", lg: "50%" }} border="1px" borderColor="gray.200" borderRadius="10px">
  <Heading mb={4} size="xl">What is Wheatpool Data Labs?</Heading>
  <Text fontSize="xl" mb={4}>We're a small non-partisan Saskatchewan based <Link color="#4A8255" href="https://en.wikipedia.org/wiki/Civic_technology">civic tech</Link> startup focused on increasing democratic engagement and transparency.  We transform existing official datasets to create fresh insights and new tools for fostering greater civic participation.  </Text>

<Text fontSize="md" mb={4}>Wheatpool Data Labs is built on a straightforward but important idea: democracy flourishes when its people are involved and informed. In a time when technology is often criticized for enabling surveillance or bombarding us with ads, we see a brighter possibility. We see technology not just as a tool, but as a way to make a real difference.</Text>

<Text fontSize="md" mb={4}>Wheatpool Data Labs emerged from a desire to reclaim technology, to transform it from a passive consumer of our attention into an active participant in our democratic process. It's about flipping the script on how we interact with digital tools, turning the tables on the usual dynamics of control and distraction.</Text>

<Text fontSize="md" mb={4}>By delving into the rich history of legislative debates, we don't just uncover the layers of governance; we lay the groundwork for a more informed, engaged, and empowered community in Saskatchewan. Our mission extends beyond merely presenting data; it's about crafting user-friendly platforms that invite participation, foster transparency, and ultimately, enhance the democratic dialogue.</Text>

<Text fontSize="md" mb={4}>In this journey, we're not just harnessing technology; we're redefining its role in our society, making it a touchstone for civic engagement and a mirror reflecting the values we cherish in a vibrant democracy.</Text>
</Box>







<Box p={5} width={{ base: "100%", md: "75%", lg: "50%" }} border="1px" borderColor="gray.200" borderRadius="10px">
  <Heading mb={4} size="xl">Who is this for?</Heading>
  <Text fontSize="xl" mb={4}>Wheatpool Data Labs welcomes everyone with an interest in Saskatchewan politics, regardless of their current knowledge level.</Text>

<Text fontSize="md">Whether you're taking your first steps into the realm of politics or you're well-versed in the field, Wheatpool Data Labs has something for you. Our mission is to make politics more approachable and engaging for all Saskatchewan citizens.</Text>

	  <UnorderedList m={7} spacing={6} fontSize="md">
	    <ListItem>Unfamiliar with your MLA or your constituency? That's perfectly fine. Enter your postal code on our platform, and quickly get up to speed with their contributions in the Legislature. It's an effortless way to begin.</ListItem>
	    <ListItem>Looking for detailed information? Our advanced search functionality allows you to refine your queries by various criteria, including party, constituency, gender, and office history, ensuring you find the precise data you need.</ListItem>
	    <ListItem>For the tech enthusiasts and scholarly researchers in the field of politics, we provide rich APIs and datasets. These resources are ideal for innovative projects or in-depth research, presenting a wealth of information at your fingertips.
</ListItem>
	  </UnorderedList>

</Box>


<Box p={5} width={{ base: "100%", md: "75%", lg: "50%" }} border="1px" borderColor="gray.200" borderRadius="10px">
  <Heading mb={4} size="xl">Where does the data come from?</Heading>
  <Text fontSize="xl" mb={3}>The data is all publicly available through the <Link color="#4A8255" href="https://www.legassembly.sk.ca/archive/index">Legislative Assembly of Saskatchewan's Meeting Archive</Link> and <Link color="#4A8255" href="https://www.saskarchives.com/">The Provincial Archives of Saskatchewan</Link>.</Text>
</Box>




<Box p={5} width={{ base: "100%", md: "75%", lg: "50%" }} border="1px" borderColor="gray.200" borderRadius="10px">
    <HStack align="start">
    <Avatar
    display={{ base: "none", md: "block" }}
    size="xl"
    src={"/scott.jpg"}
    h='200px'
    mr={25}
    w='200px'
  />    

<VStack align="start">
  <Text fontSize="xl" mb={3}><Heading mb={4} size="xl">Who made this?</Heading><Text mb={3}>Hello, I'm Scott, the guy who started Wheatpool Data Labs. I come from Regina, Saskatchewan.  I'm a lifelong learner particularly fascinated by the intersection of technology and society.</Text>

<Text mb={3} fontSize="md">My approach to technology development is rooted in openness and empowerment.  I'm committed to creating robust tools that enable others — citizens, developers, researchers — to explore new perspectives on Saskatchewan's political landscape. By providing the building blocks for innovation, my aim is to inspire a wave of civic engagement that shapes a more informed and engaged society.</Text>

<Text mb={3} fontSize="md">This project is my way of giving back to the community that shaped me. It's about breaking down barriers, turning data into dialogue, and ensuring that everyone, from the casually curious to the deeply devoted, has the tools they need to participate in our democracy.</Text>
</Text>
</VStack>

  </HStack>

</Box>





<Box p={5} width={{ base: "100%", md: "75%", lg: "50%" }} border="1px" borderColor="gray.200" borderRadius="10px">
  <Heading mb={4} size="xl">Join Us in Making a Difference</Heading>
  <Text fontSize="xl" mb={3}>Wheatpool Data Labs is a registered non-profit dedicated to enhancing democratic engagement. Our mission is fueled by passion, but sustained by the support of like-minded individuals and organizations.</Text>

<Text fontSize="md" mb={4}>If you believe in the power of technology to transform our democratic landscape in positive and meaningful ways and are interested in fostering the long-term growth of this initiative, we'd love to hear from you. Reach out to us at <em>{ "contact" + "@" + "wheatpool.ca" }</em>, and let's explore how we can collaborate for a brighter, more engaged future.</Text>

</Box>



</VStack>



	  
  );
}
